%accordians
{
    background-color: $sel-color-7-fade;
    padding: 2rem 2rem 0;
    margin-bottom: 1.5rem;
    word-break: break-word;
    text-align: left;

    > summary
    {
        background-color: $colorAccent;
        margin: -2rem -2rem 0;
        padding: .75rem 1rem;
        display: flex;
        flex-direction: row;
        gap: .5rem 1.5rem;
        align-items: center;

        > h3
        {
            color: $colorAlternate;
            margin: 0;
            line-height: 1.25;
            font-size: 1rem;
            flex-grow: 1;
        }

        > .alternate-expand
        {
            color: $colorInverse;
            font-weight: 500;
            flex: 0 0 max-content;

            &:before
            {
                content: "Expand ";
            }

            &:after
            {
                @include line-awesome();
                content: " \f107";
            }
        }

        @media (max-width: 767.98px)
        {
            flex-direction: column;
            padding-left: 1rem;
            padding-right: 1rem;
            align-items: start;

            > .alternate-expand
            {
                align-self: end;
            }
        }

        p
        {
            line-height: 1.5rem;
        }
    }

    &[open]
    {
        padding-bottom: 2rem;

        > summary
        {
            margin-bottom: 2rem;

            > .alternate-expand
            {

                &:before
                {
                    content: "Collapse ";
                }

                &:after
                {
                    content: " \f106";
                }
            }
        }
    }
}
