$mainFont: 'Montserrat', sans-serif;
$altFont: 'Montserrat', sans-serif;

$sel-color-1: #f0f8ff;
$sel-color-2: #242424;
$sel-color-3: #fff;
$sel-color-4: #ffea3d;
$sel-color-5: #023f85;
$sel-color-6: #00a8d7;
$sel-color-7: #023d5b;
$sel-color-7-fade: #f5f7f8;
$sel-color-8: #f0f8ff;

$colorInverse: $sel-color-3;
$colorBaseLight: $sel-color-1;
$colorBaseMid: $sel-color-2;
$colorBaseDark: $sel-color-2;
$colorAccent: $sel-color-7;
$colorAlternate: $sel-color-4;

$textSizeBase: 16px;
$baseShadow: 0 0 5px 0.5px rgba(black, 0.2);
$borderSize: 3px;
$altBorderSize: 2px;

$sectionPadding: 2rem;

@mixin uppercaseText()
{
    text-transform: uppercase;
}

@mixin arrowButton()
{
    display: inline-block;
    background-color: $colorAccent;
    color: $colorInverse;
    font-size: .75rem;
    font-style: normal;
    border: 0;
    padding: 1rem 4.5rem 1rem 1.5rem;
    line-height: 1;
    position: relative;
    text-decoration: none;
    @include uppercaseText();
    letter-spacing: 1px;
    font-weight: bold;
    font-family: $altFont;
    max-width: 100%;

    &:after
    {
        content: "\f30b";
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
        display: block;
        @include line-awesome();
        background-color: $colorAlternate;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
    }
}

@function encodecolor($string)
{
    @if type-of($string) == 'color'
    {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote("#{$hex}");
    }

    $string: '%23' + $string;
    @return $string;
}

@mixin line-awesome()
{
    font-family: 'Line Awesome Free';
    font-weight: bold;
}